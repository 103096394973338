import React, {useState} from "react";
import {Avatar, Typography} from "antd";
import {TbBasketDown} from "react-icons/tb";
import ChartsOverviewDemo from "../../components/charts/Chart";
import DashboardChart from "../../components/charts/Chart";
import {PiTrendUpLight} from "react-icons/pi";
import {IoWarningOutline} from "react-icons/io5";
import {TiStar} from "react-icons/ti";


export default function Dashboard() {

    const [isChart, setIsichart] = useState(false);

    return <div className={'w-full h-[550px] overflow-scroll flex flex-col border bg-green-500    rounded-xl flex flex-wrap p-2'}>
        <div className={'flex flex-row h-[30px] items-center justify-center mb-2'}>
            <div onClick={ ()=>setIsichart(false)} className={`${isChart ? 'bg-gray-400' : 'bg-white'}  w-[45%] h-[27px] rounded-lg text-center`}> Orders </div>
            <div onClick={ ()=>setIsichart(true)} className={`${isChart ? 'bg-white ': 'bg-gray-400'} w-[45%] h-[27px] rounded-lg text-center ml-2`}> Chart </div>
        </div>
        {!isChart ? <>
                {/*statistic */}
                <div className={'w-full h-[50%] flex flex-row flex-wrap items-center justify-between'}>
                    {/*item*/}
                    <div className={'w-[48%] h-[120px] flex flex-col rounded-xl bg-gray-300  justify-between'}>

                        <div className={'flex flex-row p-2 justify-between w-full'}>
                            <Typography className={''}>Total orders   </Typography>
                            <Avatar shape={'circle'} className={'bg-white'}  icon={<TbBasketDown  className={'text-black'}/>}> </Avatar>
                        </div>
                        <Typography className={'ml-2 mb-1'}> {localStorage.getItem('total') || 0} count </Typography>
                    </div>
                    {/*end item*/}

                    {/*item*/}
                    <div className={'w-[48%] h-[120px] flex flex-col rounded-xl bg-blue-600  justify-between'}>

                        <div className={'flex flex-row p-2 justify-between w-full'}>
                            <Typography className={'text-white'}>Profit  </Typography>
                            <Avatar shape={'circle'} className={'bg-white'}  icon={<PiTrendUpLight  className={'text-black'}/>}> </Avatar>
                        </div>
                        <Typography className={'ml-2 mb-1 text-white'}> {localStorage.getItem('profit') || 0} so'm</Typography>
                    </div>
                    {/*end item*/}

                    {/*item*/}
                    <div className={'w-[48%] h-[120px] flex flex-col rounded-xl bg-red-700 mt-2  justify-between'}>

                        <div className={'flex flex-row p-2 justify-between w-full'}>
                            <Typography className={'text-white'}>un completed   </Typography>
                            <Avatar shape={'circle'} className={'bg-white'}  icon={<IoWarningOutline  className={'text-black'}/>}> </Avatar>
                        </div>
                        <Typography className={'ml-2 mb-1 text-white'}> {localStorage.getItem('pending') || 0} count </Typography>
                    </div>
                    {/*end item*/}
                    {/*item*/}
                    <div className={'w-[48%] h-[120px] flex flex-col rounded-xl bg-green-600 mt-2  justify-between'}>

                        <div className={'flex flex-row p-2 justify-between w-full'}>
                            <Typography className={'text-white'}>completed   </Typography>
                            <Avatar shape={'circle'} className={'bg-white'}  icon={<TiStar  className={'text-black'}/>}> </Avatar>
                        </div>
                        <Typography className={'ml-2 mb-1 text-white'}> {localStorage.getItem('completed') || 0} count </Typography>
                    </div>
                    {/*end item*/}

                </div>
                {/*end statistic */}
            </>
            :<DashboardChart/>}

    </div>
}





import React from 'react';
import logo from '../../assets/img/logo.jpg'
import {Typography} from "antd";
const SplashScreen = () => {
    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center bg-green-400 p-6">
            <div className={'w-full h-fit bg-white rounded-lg shadow-xl rounded-xl border-t-4 border-green-700 flex flex-col items-center p-2'}>
            <img width={150} height={150} src={logo} className={'mt-4 rounded-xl'}/>
            <Typography className={'text-green-600 font-bold text-4xl mt-2'}>OsonExpress</Typography>
            <Typography className={'text-green-600 font-thin text-lg mt-1'}>Drivers panel</Typography>
            <Typography className={'text-green-600 font-thin text-lg mt-1'}>Loading ...</Typography>
            </div>
        </div>
    );
};

export default SplashScreen;

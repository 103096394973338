export function extractOrderProductCount(text) {
    const soniMatch = text.match(/soni\s*:\s*(\d+)/);
    const summaMatch = text.match(/summa\s*=\s*([\d\s]+) so`m/);

    // Новый шаблон для извлечения продуктов
    const productMatches = [...text.matchAll(/nomi\s*:\s*(.+?)\s*narxi\s*:\s*([\d\.,]+)\s*soni\s*:\s*(\d+)\s*summa\s*=\s*([\d\s]+)\s*so`m/g)];

    const soni = soniMatch ? parseInt(soniMatch[1], 10) : null;
    const summa = summaMatch ? parseInt(summaMatch[1].replace(/\s/g, ''), 10) : null;

    // Извлекаем продукты
    const products = productMatches.map(match => ({
        name: match[1].trim(),
        price: parseFloat(match[2].replace(',', '.')),
        quantity: parseInt(match[3], 10),
        total: parseInt(match[4].replace(/\s/g, ''), 10)
    }));

    return { soni, summa, products };
}

import React, {useState} from "react";
import logo from '../../assets/img/logo.jpg'
import {Button, Input, message, Typography} from "antd";
import {LoginOutlined} from "@mui/icons-material";
import {login} from "../../api/AuthApi";

export default function Auth(){
    const [phone_number,setPhoneNumber] = useState();
    const [inputPassword,setPassword] = useState();
    const loginIput = (e)=> setPhoneNumber(e.target.value)
    const passwordInput = (e)=> setPassword(e.target.value)

    const signIn= async ()=>{
        try {
            const data = await login({phoneNumber:phone_number,password:inputPassword})
            console.log(data)
            window.location.reload()
        } catch (e){
            console.log(e)
        }

    }



    return<div className={'flex flex-col h-screen w-screen items-center justify-center p-4 bg-green-950'}>
            <div className={'w-full h-[60%] bg-white rounded-lg shadow-xl rounded-xl border-t-4 border-green-800 flex flex-col items-center p-2'}>
            <img width={150} height={150} src={logo} className={'mt-4 rounded-xl'}/>
                <Typography className={'text-blue-900 font-bold text-4xl mt-2'}>OSON EXPRESS</Typography>
                <Typography className={'text-blue-900 font-thin text-lg mt-1'}>Drivers panel</Typography>
                <label className={'self-start font-thin text-[14px] text-green-700'}>Login </label>
                <Input onChange={loginIput} type={'text'} placeholder={'998 ** *** ** ** '}></Input>
                <label className={'self-start font-thin text-[14px] text-green-700'}>Password </label>
                <Input onChange={passwordInput} type={'password'} placeholder={'password'}></Input>
                <Button onClick={signIn} className={'mt-4 bg-green-600 text-white font-semibold'} icon={<LoginOutlined/>}>Sign in</Button>
            </div>
    </div>
}

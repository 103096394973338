import React, {useEffect, useState} from "react";
import {message, Badge, Card, Space, Button, Typography, Drawer} from "antd";
import {FaCheckCircle, FaTruck, FaClock, FaClipboardList, FaShoppingBasket} from "react-icons/fa"; // Новые иконки
import {getCourierOrders, getUnAssignOrders, orderCompleted, orderOnHold, orderPending} from "../../api/OrdersApi";
import {LocationOnOutlined} from "@mui/icons-material";
import {extractOrderProductCount} from "../../utils/utils";

export default function Orders({isOlyOrders}) {

    const [unAssign, setUnAssign] = useState([]);
    const [myOrders, setMyOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filter, setFilter] = useState('unAssign'); // Default filter
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const fetchOrders = async () => {
        if (localStorage.getItem('token')) {
            const undata = await getUnAssignOrders();
            const myorder = await getCourierOrders();
            localStorage.setItem('total',myorder.all_orders.length)
            let total = 0;
            myorder.completed_orders.map(value=>{
                total += parseInt(value.total)
            })
            localStorage.setItem('profit',total)
            localStorage.setItem('pending',myorder.pending_orders.length)
            localStorage.setItem('completed',myorder.completed_orders.length)
            console.log(myorder)
            setUnAssign(undata.reverse());
            setMyOrders(myorder);
            setFilteredOrders(undata); // Initialize with unassigned orders
        }
    };

    const filterOrders = (type) => {
        setFilter(type);
        let filtered = [];
        switch (type) {
            case 'unAssign':
                filtered = unAssign;
                break;
            case 'completed':
                filtered = myOrders.completed_orders;
                break;
            case 'pending':
                filtered = myOrders.pending_orders;
                break;
            case 'on_hold':
                filtered = myOrders.on_hold_orders;
                break;
            case 'today':
                filtered = myOrders.today_orders;
                break;
            default:
                filtered = myOrders.all_orders;
                break;
        }
        setFilteredOrders(filtered);
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
    }, [isOlyOrders]);

    const showDrawer = (order) => {
        setSelectedOrder(order);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setSelectedOrder(null);
    };

    return (
        <div className={'w-full h-full overflow-y-scroll overflow-x-hidden flex flex-col mt-4'}>
            <div className={'flex flex-row items-center justify-between mt-4 mb-4'}>
                <Typography className={'text-xl font-thin'}>Orders</Typography>
                <div className={'flex flex-row gap-2'}>
                    <Button onClick={() => filterOrders('unAssign')} type={filter === 'unAssign' ? 'primary' : 'default'}
                            style={{backgroundColor: 'blue', borderRadius: '50%', width: '40px', height: '40px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FaShoppingBasket style={{color: 'white', fontSize: '20px'}} />
                    </Button>
                    <Button onClick={() => filterOrders('all')} type={filter === 'all' ? 'primary' : 'default'}
                            style={{backgroundColor: 'purple', borderRadius: '50%', width: '40px', height: '40px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FaClipboardList style={{color: 'white', fontSize: '20px'}} />
                    </Button>
                    <Button onClick={() => filterOrders('completed')} type={filter === 'completed' ? 'primary' : 'default'}
                            style={{backgroundColor: 'green', borderRadius: '50%', width: '40px', height: '40px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FaCheckCircle style={{color: 'white', fontSize: '20px'}} />
                    </Button>
                    <Button onClick={() => filterOrders('pending')} type={filter === 'pending' ? 'primary' : 'default'}
                            style={{backgroundColor: 'orange', borderRadius: '50%', width: '40px', height: '40px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FaTruck style={{color: 'white', fontSize: '20px'}} />
                    </Button>
                    <Button onClick={() => filterOrders('on_hold')} type={filter === 'on_hold' ? 'primary' : 'default'}
                            style={{backgroundColor: 'red', borderRadius: '50%', width: '40px', height: '40px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FaClock style={{color: 'white', fontSize: '20px'}} />
                    </Button>
                    <Button onClick={() => filterOrders('today')} type={filter === 'today' ? 'primary' : 'default'}
                            style={{backgroundColor: '#8ca0ea', borderRadius: '50%', width: '40px', height: '40px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FaTruck style={{color: 'white', fontSize: '20px'}} />
                    </Button>
                </div>
            </div>
            <div className={'overflow-scroll h-full w-full'}>
                {filteredOrders?.map(value => (
                    <div key={value.id} onClick={() => showDrawer(value)}>
                        {item(value)}
                    </div>
                ))}
            </div>

            <Drawer
                title={<Typography.Title level={3} style={{ margin: 0, color: '#1890ff' }}>Order Details</Typography.Title>}
                placement="bottom"
                onClose={closeDrawer}
                visible={drawerVisible}
                height={'100%'}
                bodyStyle={{ padding: '24px', backgroundColor: '#f0f2f5' }}
            >
                {selectedOrder && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography.Title level={4} style={{ margin: 0 }}>Order ID: {selectedOrder.id}</Typography.Title>
                            <Typography.Text type="secondary" style={{ fontSize: '14px' }}>{new Date(selectedOrder.created_at).toLocaleString()}</Typography.Text>
                        </div>

                        <div style={{ borderTop: '1px solid #e8e8e8', paddingTop: '10px' }}>
                            <Typography.Text strong>User</Typography.Text>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography.Paragraph style={{ margin: 0 }}>
                                    {selectedOrder.user.fullname}
                                </Typography.Paragraph>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid #e8e8e8', paddingTop: '10px' }}>
                            <Typography.Text strong>Address</Typography.Text>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <LocationOnOutlined style={{ color: '#1890ff' }} />
                                <Typography.Paragraph style={{ margin: 0 }}>
                                    {selectedOrder.address}
                                </Typography.Paragraph>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid #e8e8e8', paddingTop: '10px' }}>
                            <Typography.Text strong>Total</Typography.Text>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Typography.Paragraph style={{ margin: 0, color: 'green', fontWeight: 'bold' }}>
                                    {selectedOrder.total} so`m
                                </Typography.Paragraph>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid #e8e8e8', paddingTop: '10px' }}>
                            <Typography.Text strong>Description</Typography.Text>
                            <Typography.Paragraph style={{ margin: 0 }}>
                                {selectedOrder.description}
                            </Typography.Paragraph>
                        </div>

                        <div style={{ borderTop: '1px solid #e8e8e8', paddingTop: '10px' }}>
                            <Typography.Text strong>Products</Typography.Text>
                            {selectedOrder.description && extractOrderProductCount(selectedOrder.description).products.map((product, index) => (
                                <div key={index} style={{ padding: '8px 0', borderBottom: '1px solid #e8e8e8' }}>
                                    <Typography.Text><strong>{product.name}</strong></Typography.Text>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography.Text>Price: {product.price} so`m</Typography.Text>
                                        <Typography.Text>Quantity: {product.quantity}</Typography.Text>
                                        <Typography.Text>Total: {product.total} so`m</Typography.Text>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Drawer>



        </div>
    );
}

const pendOrder = async (id) => {
    try {
        await orderPending({order_id: id});
        message.success('вы приняли этот заказ');
    } catch (e) {
        message.error('что-то пошло не так');
    }
};

const completeOrder = async (id) => {
    try {
        await orderCompleted({order_id: id});
        message.success('заказ доставлен');
    } catch (e) {
        message.error('что-то пошло не так');
    }
};

const onHold = async (id) => {
    try {
        await orderOnHold({order_id: id});
        message.success('заказ в ожидании');
    } catch (e) {
        message.error('что-то пошло не так');
    }
};

const item = (data) => {
    return (
        <Space
            direction="vertical"
            size="middle"
            style={{width: '97%'}}
        >
            <Badge.Ribbon
                color={data.status === 'on hold' ? 'red' : data.status === 'completed' ? 'green' : data.status === 'pending' ? "blue" : ''}
                text={data.status}
            >
                <Card className={'bg-gray-100'} title={
                    <div className={'w-full flex flex-row items-center'}>
                        {data?.user.fullname}
                        <div className={'self-end w-full flex flex-row items-center justify-end pr-14 text-white text-[18px]'}>
                            <div onClick={() => completeOrder(data?.id)} className={'w-[30px] h-[30px] bg-green-400 rounded flex flex-col items-center justify-center mr-2'}>
                                <FaCheckCircle/>
                            </div>
                            <div onClick={() => pendOrder(data?.id)} className={'w-[30px] h-[30px] bg-blue-500 rounded flex flex-col items-center justify-center mr-2'}>
                                <FaTruck/>
                            </div>
                            <div onClick={() => onHold(data?.id)} className={'w-[30px] h-[30px] bg-rose-600 rounded flex flex-col items-center justify-center'}>
                                <FaClock/>
                            </div>
                        </div>
                    </div>
                } size="small">
                    <div className={'w-full h-[50px] flex flex-col items-center ml-2'}>
                        <div className={'w-full h-[15px] flex flex-row items-center justify-between'}>
                            <div className={'w-fit h-[20px] bg-sky-500 text-white rounded text-[12px] p-1 text-center flex flex-row items-center'}>
                                Summa: {data.total} so`m
                            </div>
                            <div className={'w-fit h-[20px] bg-green-500 text-white rounded text-[12px] p-1 text-center flex flex-row items-center'}>
                                {data?.user.phoneNumber}
                            </div>
                        </div>
                        <div className={'w-full h-fit bg-green-500 text-white rounded p-1 text-start mt-2'}>
                            <LocationOnOutlined className={'text-white'}/> {data?.address}
                        </div>
                    </div>
                </Card>
            </Badge.Ribbon>
        </Space>
    );
};

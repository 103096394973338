import React, {useState} from "react";
import Dashboard from "../../layers/Dashboard/Dashboard";
import Orders from "../../layers/Orders/Orders";
import {Fullscreen, Map} from "@mui/icons-material";
import {AiOutlineDollar} from "react-icons/ai";

export default function Home(){
    const [isOnlyOrders, setIsOnlyOrders] = useState(false);

    return<div className={'w-full h-screen flex flex-col p-4 '}>
        <div className={'w-full h-[40px] flex flex-row items-center justify-end bg-green-500 rounded-md mb-1 p-1 border-2 border-gray-200'}>
            <div className={'w-[30px] h-[30px] flex flex-row bg-white rounded-md mr-2 items-center justify-center'}><AiOutlineDollar/> </div>
            <div className={'w-[30px] h-[30px] flex flex-row bg-white rounded-md mr-2 items-center justify-center'}><Map/> </div>
            <div onClick={()=>setIsOnlyOrders(!isOnlyOrders)} className={'bg-white rounded-md w-[30px] h-[30px] flex flex-row items-center justify-center'}><Fullscreen/></div>
        </div>
        {!isOnlyOrders &&  <Dashboard/>}
        <Orders isOlyOrders={isOnlyOrders}/>
    </div>
}

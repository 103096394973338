import React from "react";
import {Avatar, Space, Typography} from "antd";
import {TiUserOutline} from "react-icons/ti";
import LanguageSelect from "./items/LanguageSelect";
import { Fullscreen} from "@mui/icons-material";
export default function Header({onlyOrdersHandler,isOnlyOrders}) {

    return <div className={'w-full h-[60px] bg-green-700 rounded-b-xl flex flex-row items-center pl-4 justify-between'}>
        <div className={'flex flex-row items-center'}>
        <Avatar className={'bg-white'} icon={<TiUserOutline className={'text-gray-900'}/>}/>
        <Typography className={'text-white font-bold ml-2'}>Farrukh Pulatov </Typography>
        </div>

        <div className={'flex flex-row text-white'}>
        <LanguageSelect/>
        </div>
    </div>
}
